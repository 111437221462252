import React from 'react';
import { PageMetadata } from './PageMetadata';
import { generateMetadata } from '../../metadata/siteMetadata';

interface ArticleLayoutProps {
  children: React.ReactNode;
  metadata: ReturnType<typeof generateMetadata>;
}

const ArticleLayout: React.FC<ArticleLayoutProps> = ({ children, metadata }) => {
  return (
    <div className="min-h-screen bg-white dark:bg-dark">
      <PageMetadata {...metadata} />
      <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 lg:pt-28 pb-24">
        <article>
          {children}
        </article>
      </main>
    </div>
  );
};

export default ArticleLayout; 