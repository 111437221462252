// src/components/home/index.tsx
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { PageMetadata } from '../shared/PageMetadata';
import { generateMetadata } from '../../metadata/siteMetadata';
import {
  AlertTriangle,
  Award,
  BookOpen,
  Users,
  BrainCircuit,
  BarChart2,
  UsersRound,
  GraduationCap,
  ArrowRight
} from 'lucide-react';
import {
  ConditionPerformanceChart,
  PerformanceComparisonChart,
  SubgroupChart,
} from '../shared/Charts';
import { ShinyButton, SidebarNav } from '../shared/Shared';
import { Element } from 'react-scroll';
import ImageShowcase from '../shared/ImageShowcase';
import { ARTICLES, Article, getArticleDate } from '../../constants/articles';
import { formatDate } from '../../utils/dateUtils';

// Utility function to format date as "Month Day, Year"
// const formatDate = (dateString: string) => {
//   const date = new Date(dateString);
//   return date.toLocaleDateString('en-US', {
//     month: 'long',
//     day: 'numeric',
//     year: 'numeric'
//   });
// };

const sortByDate = (a: Article, b: Article) => {
  const dateA = getArticleDate(a.path);
  const dateB = getArticleDate(b.path);
  
  if (!dateA && !dateB) return 0;
  if (!dateA) return 1;
  if (!dateB) return -1;
  return new Date(dateB).getTime() - new Date(dateA).getTime();
};

// Text Content
const content = {
  hero: {
    title: "Ensuring No Disease is Missed in Radiology",
    subtitle: "We're revolutionizing radiology with comprehensive AI that works the way radiologists work. Our first breakthrough, a2z-1, demonstrates this vision by evaluating multiple findings simultaneously across abdomen-pelvis CT studies.",
    buttonText: "Schedule a Demo",
  },
  impact: {
    title: "Transforming Radiology",
    description:
      "Our comprehensive approach is changing how AI supports radiologists:",
    points: [
      {
        title: "Complete Coverage",
        description:
          "Starting with abdomen-pelvis CT, our technology evaluates multiple findings simultaneously, ensuring nothing is missed in the interpretation process.",
        icon: BarChart2,
      },
      {
        title: 'Proven Performance',
        description:
          'Validated across multiple international centers with >95% AUC for various findings, demonstrating the potential of our comprehensive approach.',
        icon: UsersRound,
      },
      {
        title: 'Systematic Innovation',
        description:
          'Building a suite of AI solutions that work together seamlessly, starting with abdomen-pelvis CT and expanding to other critical areas of radiology.',
        icon: GraduationCap,
      },
    ],
  },
  howItWorks: {
    title: 'A New Vision for Radiology AI',
    description: 'Our approach transforms how AI supports radiologists:',
    steps: [
      {
        title: 'Beyond Point Solutions',
        description:
          'While existing tools create fragmented workflows by focusing on individual findings, our technology evaluates multiple findings simultaneously across entire studies.',
      },
      {
        title: 'Working Like Radiologists',
        description:
          'Developed through years of studying radiologist workflows, our AI mirrors how radiologists actually work - considering everything from A to Z in each study.',
      },
      {
        title: 'Continuous Evolution',
        description:
          'Starting with abdomen-pelvis CT, we\'re building a comprehensive suite of AI tools that will transform radiology interpretation across modalities.',
      }
    ],
  },
  validation: {
    title: 'Proven Performance',
    points: [
      {
        title: 'International Validation',
        description:
          'Validated across multiple international centers, demonstrating consistent performance across diverse clinical settings.',
        icon: Award,
      },
      {
        title: 'Clinical Impact',
        description:
          'Demonstrated ability to identify time-sensitive findings that require immediate attention, enhancing patient care.',
        icon: AlertTriangle,
      },
      {
        title: 'Quality Focused',
        description:
          'Rigorous development process ensuring our technology meets the highest standards of performance and reliability.',
        icon: Users,
      },
    ],
  },
  takeNextStep: {
    title: "Experience the Future of Radiology AI",
    description:
      "We're collaborating with leading healthcare institutions to transform radiology. Schedule a demo to:",
    userTypes: [
      {
        title: 'Early Access',
        description:
          'Be among the first to experience and implement our comprehensive AI solutions.',
      },
      {
        title: 'Shape the Future',
        description:
          'Work directly with our team of AI researchers, clinical radiologists, and industry experts to influence the evolution of radiology AI.',
      },
      {
        title: 'Drive Innovation',
        description:
          'Help shape the future of comprehensive AI solutions in radiology.',
      },
    ],
    buttonText: "Schedule a Demo",
  },
  importantNotice: {
    title: 'Regulatory Status',
    description:
      'Our technology is currently under development and regulatory review. It has not yet received regulatory clearance and is not approved for clinical or diagnostic use by the FDA or other regulatory bodies.',
  },
};

// Add this to the content object
const validationPages = [
  {
    title: 'Performance Data',
    description: 'See how our technology performs across different clinical settings.',
    icon: Award,
    path: '/validation/external',
    buttonText: "Schedule a Demo"
  },
  {
    title: 'Quality Analysis',
    description: 'Learn about our rigorous approach to performance validation.',
    icon: AlertTriangle,
    path: '/validation/miss-analysis',
    buttonText: "Schedule a Demo"
  },
  {
    title: 'Future Development',
    description: 'Discover our roadmap for expanding comprehensive AI across radiology.',
    icon: Users,
    path: '/validation/subgroup',
    buttonText: "Schedule a Demo"
  }
];

// Hero Section Component
const HeroSection = ({ openCalendly }: { openCalendly: () => void }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  // Get the most recent article
  const latestArticle = [...ARTICLES]
    .sort(sortByDate)[0];

  return (
    <section
      className="relative overflow-hidden flex items-center py-24 sm:py-32 px-4 sm:px-6 lg:px-8 min-h-[90vh] bg-gradient-to-br from-gray-50 via-white to-gray-50 dark:from-gray-950 dark:via-gray-900 dark:to-gray-950"
      id="home"
    >
      {/* Background gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-primary/5 via-transparent to-accent/5 dark:from-primary/10 dark:to-accent/10" />
      
      {/* Radial gradient for subtle emphasis */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-primary/5 via-transparent to-transparent dark:from-accent/10" />

      <div className="relative z-10 max-w-5xl mx-auto flex flex-col lg:flex-row items-center">
        {/* Text Content */}
        <div className="w-full lg:w-1/2 lg:pr-12">
          <motion.div
            className="space-y-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <motion.h1
              className="text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-900 dark:text-accent text-left leading-tight"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              {content.hero.title}
            </motion.h1>
            <motion.p
              className="text-xl sm:text-2xl text-gray-700 dark:text-gray-300 text-left leading-relaxed"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              {content.hero.subtitle}
            </motion.p>

            {/* Latest Article Preview */}
            {latestArticle && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.3 }}
                className="mt-8"
              >
                <Link
                  to={latestArticle.path}
                  className="group block bg-white/80 dark:bg-gray-900/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 rounded-xl p-6 hover:bg-gray-50 dark:hover:bg-gray-900/60 transition-all duration-300"
                >
                  <div className="flex items-start space-x-4">
                    <div className="bg-primary/10 dark:bg-primary/20 p-3 rounded-xl">
                      <latestArticle.icon className="w-6 h-6 text-primary dark:text-accent" />
                    </div>
                    <div className="flex-1">
                      <div className="flex items-center gap-3 mb-3">
                        <span className="text-sm bg-primary/10 dark:bg-accent/10 text-primary dark:text-accent px-2 py-1 rounded-full">
                          Latest Update
                        </span>
                        {getArticleDate(latestArticle.path) && (
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {formatDate(getArticleDate(latestArticle.path)!)}
                          </span>
                        )}
                      </div>
                      <h3 className="text-xl font-semibold text-gray-900 dark:text-accent mb-3 group-hover:text-primary dark:group-hover:text-accent transition-colors">
                        {latestArticle.title}
                      </h3>
                      <p className="text-gray-700 dark:text-gray-300 mb-4 line-clamp-2">
                        {latestArticle.description}
                      </p>
                      <div className="flex items-center text-primary dark:text-accent font-semibold">
                        Read Article
                        <ArrowRight className="ml-2 h-4 w-4 transform group-hover:translate-x-1 transition-transform" />
                      </div>
                    </div>
                  </div>
                </Link>
              </motion.div>
            )}

            {/* Button Container */}
            <motion.div 
              className="flex justify-start pt-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <ShinyButton
                onClick={openCalendly}
                className="!py-3 !px-8 !text-lg sm:mx-0 mx-auto mb-4 lg:mb-0"
              >
                {content.hero.buttonText}
              </ShinyButton>
            </motion.div>
          </motion.div>
        </div>

        {/* Visual Content */}
        <div className="w-full lg:w-1/2 mt-12 lg:mt-0">
          <motion.div
            className="relative w-full"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{
              opacity: isImageLoaded ? 1 : 0,
              scale: isImageLoaded ? 1 : 0.9,
            }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            {/* Decorative elements */}
            <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/30 to-accent/30 rounded-lg blur opacity-30" />
            <div className="relative">
              <img
                src="/examples/detections/EM9_pancreas_acute_pancreatitis.png"
                alt="AI-Generated Insight: Abdomen-Pelvis CT"
                className="rounded-lg shadow-2xl w-full object-contain-custom bg-gray-100 dark:bg-gray-900/50 backdrop-blur-sm"
                onLoad={() => setIsImageLoaded(true)}
              />
              {/* Floating accent elements */}
              <div className="absolute -top-6 -right-6 w-24 h-24 bg-primary/10 dark:bg-accent/10 rounded-full blur-2xl" />
              <div className="absolute -bottom-8 -left-8 w-32 h-32 bg-accent/10 dark:bg-primary/10 rounded-full blur-2xl" />
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

// Real-World Impact Section
const RealWorldImpactSection: React.FC = () => {
  const impactPoints = content.impact.points;

  return (
    <section id="impact" className="bg-white dark:bg-dark">
      {/* Main content with max-width */}
      <div className="py-10 px-4 sm:px-8 max-w-4xl mx-auto">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold mb-6 text-gray-900 dark:text-gray-200 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          {content.impact.title}
        </motion.h2>
        <motion.p
          className="text-md sm:text-xl mb-6 text-gray-700 dark:text-gray-300 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          {content.impact.description}
        </motion.p>
        <ul className="space-y-6 mb-12">
          {impactPoints.map((item, index) => (
            <motion.li
              key={index}
              className="flex items-start"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.4 + index * 0.2 }}
            >
              <item.icon className="mr-4 h-6 w-6 text-primary dark:text-primary flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-200">
                  {item.title}
                </h3>
                <p className="text-gray-700 dark:text-gray-300">
                  {item.description}
                </p>
              </div>
            </motion.li>
          ))}
        </ul>
      </div>
      
      {/* Full-width ImageShowcase section */}
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="px-4 sm:px-8"
        >
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-gray-200 mb-6">
            Example Detections
          </h3>
          <ImageShowcase disableBackground />
        </motion.div>
      </div>
    </section>
  );
};

// How a2z-1 Works Section
const HowA2z1WorksSection = () => {
  const steps = content.howItWorks.steps;

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <section id="how-it-works" className="bg-gray-50 dark:bg-dark">
      <div className="py-10 px-4 sm:px-8 max-w-4xl mx-auto">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold mb-6 text-gray-900 dark:text-gray-200 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          {content.howItWorks.title}
        </motion.h2>
        <motion.p
          className="text-md sm:text-xl mb-8 text-gray-700 dark:text-gray-300 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          {content.howItWorks.description}
        </motion.p>
        {/* Workflow Steps */}
        <motion.div
          className="flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {steps.map((step, index) => (
            <motion.div
              key={index}
              className="flex-1 flex items-start"
              variants={itemVariants}
            >
              {/* Numbered Badge */}
              <div className="relative mr-4">
                <div className="flex items-center justify-center h-10 w-10 bg-primary text-white rounded-full text-lg font-bold">
                  {index + 1}
                </div>
              </div>
              {/* Step Details */}
              <div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-200">
                  {step.title}
                </h3>
                <p className="text-gray-700 dark:text-gray-300">
                  {step.description}
                </p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

// Scientific Validation Section with Tabbed Interface
const ScientificValidationSection = () => {
  const validationPoints = content.validation.points;
  const [activeTab, setActiveTab] = useState(0);

  // Define the tabs array with proper typing
  interface Tab {
    label: string;
    component: JSX.Element;
  }

  const tabs: Tab[] = [
    {
      label: 'Condition Performance',
      component: <ConditionPerformanceChart />,
    },
    {
      label: 'Performance Comparison',
      component: <PerformanceComparisonChart />,
    },
    {
      label: 'Subgroup Analysis',
      component: <SubgroupChart />
    },
  ];

  return (
    <section id="validation" className="bg-white dark:bg-dark">
      <div className="py-10 px-4 sm:px-8 max-w-4xl mx-auto">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold mb-6 text-gray-900 dark:text-gray-200 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          {content.validation.title}
        </motion.h2>

        {/* Validation Page Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
          {validationPages.map((page, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 * index }}
            >
              <Link
                to={page.path}
                className="block h-full bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 p-6"
              >
                <page.icon className="h-12 w-12 text-primary dark:text-primary mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-200 mb-2">
                  {page.title}
                </h3>
                <p className="text-gray-700 dark:text-gray-300">
                  {page.description}
                </p>
                <div className="mt-4 text-primary dark:text-accent font-semibold flex items-center">
                  {page.buttonText}
                </div>
              </Link>
            </motion.div>
          ))}
        </div>

        {/* Rest of the existing validation content */}
        <div className="space-y-8">
          {validationPoints.map((item, index) => (
            <motion.div
              key={index}
              className="flex items-start"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 * index }}
            >
              <item.icon className="mr-4 h-8 w-8 text-primary flex-shrink-0" />
              <div>
                <h3 className="text-2xl font-semibold text-gray-900 dark:text-gray-200">
                  {item.title}
                </h3>
                <p className="text-gray-700 dark:text-gray-300">
                  {item.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Tabbed Interface */}
        <div className="mt-12">
          {/* Tab Headers */}
          <div className="flex border-b border-gray-300 dark:border-gray-700">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`py-2 px-4 -mb-px font-semibold text-sm transition-colors duration-300 focus:outline-none ${
                  activeTab === index
                    ? 'border-b-2 border-primary text-primary'
                    : 'text-gray-600 dark:text-gray-300 hover:text-primary'
                }`}
                onClick={() => setActiveTab(index)}
                aria-selected={activeTab === index}
                role="tab"
                aria-controls={`tabpanel-${index}`}
                id={`tab-${index}`}
              >
                {tab.label}
              </button>
            ))}
          </div>

          {/* Tab Panels */}
          <div className="mt-4">
            {tabs.map((tab, index) => (
              <AnimatePresence key={index}>
                {activeTab === index && (
                  <motion.div
                    id={`tabpanel-${index}`}
                    role="tabpanel"
                    aria-labelledby={`tab-${index}`}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                  >
                    {tab.component}
                  </motion.div>
                )}
              </AnimatePresence>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

// Take the Next Step Section
const TakeNextStepSection = ({ openCalendly }: { openCalendly: () => void }) => {
  return (
    <section
      className="relative overflow-hidden flex items-center py-24 sm:py-32 px-4 sm:px-6 lg:px-8 min-h-[80vh] bg-gradient-to-br from-gray-50 via-white to-gray-100 dark:from-gray-950 dark:via-gray-900 dark:to-gray-950"
      id="take-the-next-step"
    >
      <div className="relative z-10 max-w-4xl mx-auto">
        <motion.h1
          className="text-4xl sm:text-5xl font-bold mb-6 text-gray-900 dark:text-accent"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          {content.takeNextStep.title}
        </motion.h1>
        <motion.p
          className="text-xl sm:text-2xl mb-12 text-gray-700 dark:text-gray-300"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          {content.takeNextStep.description}
        </motion.p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {content.takeNextStep.userTypes.map((type, index) => (
            <motion.div
              key={index}
              className="bg-white/80 dark:bg-gray-900/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 rounded-xl p-6 hover:bg-gray-50 dark:hover:bg-gray-900/60 transition-all duration-300"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 * (index + 1) }}
            >
              <div className="bg-primary/10 dark:bg-primary/20 p-3 rounded-xl mb-4">
                {index === 0 && <Users className="h-8 w-8 text-primary dark:text-accent" />}
                {index === 1 && <BrainCircuit className="h-8 w-8 text-primary dark:text-accent" />}
                {index === 2 && <BookOpen className="h-8 w-8 text-primary dark:text-accent" />}
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-accent mb-3">
                {type.title}
              </h3>
              <p className="text-gray-700 dark:text-gray-300">
                {type.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Related Content Section
const RelatedContent = () => {
  // Get the most recent articles
  const recentArticles = [...ARTICLES]
    .sort(sortByDate)
    .slice(0, 3);

  return (
    <section className="py-24 bg-gradient-to-br from-white via-gray-50 to-white dark:from-gray-950 dark:via-gray-900 dark:to-gray-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 dark:text-accent mb-4">
            Latest Updates
          </h2>
          <p className="text-xl text-gray-700 dark:text-gray-300">
            Stay informed about our latest developments and insights
          </p>
        </div>

        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {recentArticles.map((article, index) => (
            <motion.div
              key={article.path}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <Link
                to={article.path}
                className="block h-full bg-white/80 dark:bg-gray-900/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 rounded-xl p-6 hover:bg-gray-50 dark:hover:bg-gray-900/60 transition-all duration-300"
              >
                <div className="flex items-start space-x-4">
                  <div className="bg-primary/10 dark:bg-primary/20 p-3 rounded-xl">
                    <article.icon className="w-6 h-6 text-primary dark:text-accent" />
                  </div>
                  <div>
                    <div className="flex items-center gap-3 mb-2">
                      <span className="text-sm text-primary dark:text-accent font-medium">
                        {article.category}
                      </span>
                      {getArticleDate(article.path) && (
                        <span className="text-sm text-gray-500 dark:text-gray-400">
                          {formatDate(getArticleDate(article.path)!)}
                        </span>
                      )}
                    </div>
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-accent mb-2">
                      {article.title}
                    </h3>
                    <p className="text-gray-700 dark:text-gray-300 mb-4">
                      {article.description}
                    </p>
                    <div className="flex items-center text-primary dark:text-accent font-semibold">
                      Read More
                      <ArrowRight className="ml-2 h-4 w-4" />
                    </div>
                  </div>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>

        <div className="text-center mt-12">
          <Link
            to="/updates"
            className="inline-flex items-center px-6 py-3 text-base font-medium rounded-xl text-primary dark:text-accent hover:text-accent dark:hover:text-primary transition-colors duration-300"
          >
            View All Updates
            <ArrowRight className="ml-2 h-4 w-4" />
          </Link>
        </div>
      </div>
    </section>
  );
};

// Main Homepage Component
interface HomeProps {
  openCalendly: () => void;
}

const Home: React.FC<HomeProps> = ({ openCalendly }) => {
  // Define navItems to pass to SidebarNav
  const navItems = [
    { name: 'How It Works', to: 'how-it-works' },
    { name: 'Impact', to: 'impact' },
    { name: 'Validation', to: 'validation' },
    { name: 'Latest News', to: 'related-content' },
    { name: 'Join Our Program', to: 'take-the-next-step' },
  ];

  const metadata = generateMetadata({
    title: 'a2z Radiology AI: AI-Powered Analytics for Radiologists',
    description: 'a2z Radiology AI provides AI-powered analytics for radiologists, with a2z-1 for abdominal-pelvis CT interpretation.',
    type: 'website'
  });

  return (
    <div className="min-h-screen">
      <PageMetadata {...metadata} />

      <SidebarNav sections={navItems} />

      <div className="lg:mx-4">
        <Element name="home" className="animate-slide-up">
          <HeroSection openCalendly={openCalendly} />
        </Element>

        <Element name="how-it-works" className="animate-slide-up">
          <HowA2z1WorksSection />
        </Element>

        <Element name="impact" className="animate-slide-up">
          <RealWorldImpactSection />
        </Element>

        <Element name="validation" className="animate-slide-up">
          <ScientificValidationSection />
        </Element>

        <Element name="related-content" className="animate-slide-up">
          <RelatedContent />
        </Element>

        <Element name="take-the-next-step" className="animate-slide-up">
          <TakeNextStepSection openCalendly={openCalendly} />
        </Element>
      </div>
    </div>
  );
};

export default Home;
