import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { InlineWidget } from 'react-calendly';
import Home from './components/home';
import { Navbar, Footer, ScrollProgressBar } from './components/shared/Shared';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import NotFound from './components/pages/NotFound';

// Performance pages
import Performance from './components/performance';
import CriticalFindings from './components/performance/critical-findings';
import A2ZWins from './components/performance/a2z-wins-look-everywhere';
import ExternalValidation from './components/performance/external-validation';
import SubgroupAnalysis from './components/performance/subgroup-analysis';
import MissAnalysis from './components/performance/miss-analysis';
import VesselOcclusionWins from './components/performance/vessel-occlusion';
import InflammatoryIntelligence from './components/performance/inflammatory-intelligence';

// Research pages
import Research from './components/research';
import ResearchPaper from './components/research/a2z1preprint';

// News pages
import FTMention from './components/news/FTMention';
import RSNAMention from './components/news/RSNAMention';
import A2ZStory from './components/news/a2zstory';
import NYTGuestEssay from './components/news/NYTGuestEssay';

// Updates pages
import Updates from './components/blog';

// ScrollToTop component to handle scroll behavior on route changes
const ScrollToTop = () => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return null;
};

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(() => {
    // Always default to dark mode
    if (typeof window !== 'undefined') {
      localStorage.setItem('theme', 'dark');
      return true;
    }
    return true;
  });

  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
  const openCalendly = () => setIsCalendlyOpen(true);
  const closeCalendly = () => setIsCalendlyOpen(false);

  useEffect(() => {
    const root = document.documentElement;
    if (isDarkMode) {
      root.classList.add('dark');
    } else {
      root.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
  };

  return (
    <HelmetProvider>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => window.location.reload()}
      >
        <div className="flex flex-col min-h-screen">
          <ScrollToTop />
          <ScrollProgressBar />
          <Navbar openCalendly={openCalendly} />
          
          <main className="flex-grow">
            <Routes>
              {/* Main routes */}
              <Route path="/" element={<Home openCalendly={openCalendly} />} />
              <Route path="/story" element={<A2ZStory />} />
              
              {/* Performance routes */}
              <Route path="/performance" element={<Performance />} />
              <Route path="/performance/inflammatory-intelligence" element={<InflammatoryIntelligence openCalendly={openCalendly} />} />
              <Route path="/performance/vessel-occlusion-wins" element={<VesselOcclusionWins openCalendly={openCalendly} />} />
              <Route path="/performance/critical-findings" element={<CriticalFindings openCalendly={openCalendly} />} />
              <Route path="/performance/a2z-wins-look-everywhere" element={<A2ZWins openCalendly={openCalendly} />} />
              <Route path="/performance/external-validation" element={<ExternalValidation openCalendly={openCalendly} />} />
              <Route path="/performance/subgroup-analysis" element={<SubgroupAnalysis openCalendly={openCalendly} />} />
              <Route path="/performance/miss-analysis" element={<MissAnalysis openCalendly={openCalendly} />} />
              
              {/* Research routes */}
              <Route path="/research" element={<Research />} />
              <Route path="/research/paper" element={<ResearchPaper openCalendly={openCalendly} />} />
              
              {/* News routes */}
              <Route path="/news/ft-mention" element={<FTMention openCalendly={openCalendly} />} />
              <Route path="/news/rsna-mention" element={<RSNAMention openCalendly={openCalendly} />} />
              <Route path="/news/nyt-guest-essay" element={<NYTGuestEssay openCalendly={openCalendly} />} />
              
              {/* Updates routes */}
              <Route path="/updates" element={<Updates />} />
              <Route path="/updates/inflammatory-intelligence" element={<InflammatoryIntelligence openCalendly={openCalendly} />} />
              <Route path="/updates/nyt-guest-essay" element={<NYTGuestEssay openCalendly={openCalendly} />} />
              <Route path="/updates/a2z-wins-look-everywhere" element={<A2ZWins openCalendly={openCalendly} />} />
              <Route path="/updates/critical-findings" element={<CriticalFindings openCalendly={openCalendly} />} />
              <Route path="/updates/research-paper" element={<ResearchPaper openCalendly={openCalendly} />} />
              <Route path="/updates/external-validation" element={<ExternalValidation openCalendly={openCalendly} />} />
              <Route path="/updates/miss-analysis" element={<MissAnalysis openCalendly={openCalendly} />} />
              <Route path="/updates/subgroup-analysis" element={<SubgroupAnalysis openCalendly={openCalendly} />} />
              <Route path="/updates/rsna-2024" element={<RSNAMention openCalendly={openCalendly} />} />
              <Route path="/updates/ft-feature" element={<FTMention openCalendly={openCalendly} />} />
              
              {/* Static Pages */}
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              
              {/* Redirects from old paths */}
              <Route path="/research/*" element={<Navigate to="/updates" replace />} />
              <Route path="/news/*" element={<Navigate to="/updates" replace />} />
              <Route path="/validation/*" element={<Navigate to="/updates" replace />} />
              <Route path="/blog/*" element={<Navigate to="/updates" replace />} />
              
              {/* 404 */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>

          <Footer 
            isDarkMode={isDarkMode}
            toggleTheme={toggleTheme}
            openCalendly={openCalendly}
          />

          {isCalendlyOpen && (
            <>
              <div 
                className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[9998]"
                onClick={closeCalendly}
              />
              <div className="fixed inset-4 md:inset-10 z-[9999] bg-white dark:bg-gray-900 rounded-xl overflow-hidden">
                <InlineWidget
                  url="https://calendly.com/d/cmg5-cyr-q57"
                  styles={{ height: '100%', width: '100%' }}
                />
              </div>
            </>
          )}
        </div>
      </ErrorBoundary>
    </HelmetProvider>
  );
};

const ErrorFallback = ({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) => (
  <div className="min-h-screen flex items-center justify-center p-4">
    <div className="text-center">
      <h1 className="text-2xl font-bold mb-4">Something went wrong</h1>
      <pre className="text-sm text-red-500 mb-4">{error.message}</pre>
      <button
        onClick={resetErrorBoundary}
        className="bg-primary text-white px-4 py-2 rounded hover:bg-primary/90"
      >
        Try again
      </button>
    </div>
  </div>
);

export default App;
