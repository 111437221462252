import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { PageMetadata } from '../shared/PageMetadata';
import { generateMetadata } from '../../metadata/siteMetadata';
import { getAllContent, ContentData } from '../../utils/contentLoader';

const Performance = () => {
  const [articles, setArticles] = useState<ContentData[]>([]);

  useEffect(() => {
    const loadArticles = async () => {
      const performanceContent = await getAllContent('performance');
      setArticles(performanceContent);
    };
    loadArticles();
  }, []);

  const metadata = generateMetadata({
    title: 'Performance Analysis | a2z Radiology AI',
    description: 'Comprehensive analysis of a2z-1\'s performance across diverse clinical settings, including validation studies and real-world case studies.',
    type: 'website'
  });

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-950 via-gray-900 to-gray-950">
      <PageMetadata {...metadata} />
      
      <main className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <motion.h1 
            className="text-4xl sm:text-5xl font-bold mb-6 text-accent"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Performance Analysis
          </motion.h1>
          
          <motion.p 
            className="text-xl text-gray-300 mb-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Explore comprehensive analyses of a2z-1's performance across diverse clinical settings, from validation studies to real-world case studies.
          </motion.p>

          <div className="space-y-8">
            {articles.map((article, index) => (
              <motion.div
                key={article.metadata.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 * (index + 1) }}
              >
                <Link 
                  to={`/performance/${article.metadata.path || article.metadata.title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`}
                  className="block bg-gray-900/50 backdrop-blur-sm border border-gray-800 rounded-xl p-6 hover:bg-gray-900/60 transition-all duration-300"
                >
                  <h2 className="text-2xl font-semibold text-accent mb-2">
                    {article.metadata.title}
                  </h2>
                  <p className="text-gray-300 mb-4">
                    {article.metadata.description}
                  </p>
                  <div className="flex items-center text-accent font-semibold">
                    Read Analysis
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Performance; 