import React from 'react';
import { generateMetadata } from '../../metadata/siteMetadata';
import { DATES } from '../../constants/articles';
import ArticleLayout from '../shared/ArticleLayout';
import { AuthorSection } from '../shared/Shared';
import { getAuthorSection } from '../../metadata/authorMetadata';

interface NYTGuestEssayProps {
  openCalendly: () => void;
}

const NYTGuestEssay: React.FC<NYTGuestEssayProps> = ({ openCalendly }) => {
  const metadata = generateMetadata({
    title: "The Robot Doctor Will See You Now",
    description: "Dr. Pranav Rajpurkar and Dr. Eric Topol discuss in the New York Times how AI is outperforming doctors in certain tasks and propose a new division of labor between human physicians and AI systems.",
    type: 'article',
    publishedDate: DATES.NYT_GUEST_ESSAY
  });

  const authorSection = getAuthorSection(DATES.NYT_GUEST_ESSAY, ['Pranav Rajpurkar']);

  return (
    <ArticleLayout metadata={metadata}>
      <article>
        <header className="mb-8">
          <h1 className="text-4xl sm:text-5xl font-bold text-accent mb-6">
            {metadata.title}
          </h1>
          
          <AuthorSection {...authorSection} />
        </header>

        <div className="mb-8 space-y-6">
          <p className="text-lg font-medium">
            In a thought-provoking guest essay for The New York Times, our co-founder Dr. Pranav Rajpurkar and renowned physician-scientist 
            Dr. Eric Topol explore the evolving relationship between AI and physicians in healthcare.
          </p>

          <figure className="my-8">
            <div className="aspect-w-16 aspect-h-9 bg-gray-100 dark:bg-gray-800 rounded-xl overflow-hidden">
              <img
                src="/images/news/nyt-guest-essay.jpg"
                alt="Illustration by Giacomo Bagnara for The New York Times - A medical glove holding up a medical scan with a microchip processor"
                className="object-cover w-full h-full"
              />
            </div>
            <figcaption className="mt-2 text-sm text-gray-600 dark:text-gray-400 text-center">
              Credit: Illustration by Giacomo Bagnara for The New York Times
            </figcaption>
          </figure>

          <p className="text-lg">
            The essay, drawing from recent research including studies from MIT-Harvard and Harvard-Stanford, challenges the common assumption 
            that combining AI with physician expertise automatically leads to better outcomes. Instead, it proposes a more nuanced approach 
            to integrating AI in healthcare.
          </p>

          <div className="my-8 p-6 bg-gray-50 dark:bg-gray-900 rounded-xl">
            <blockquote className="text-lg italic border-l-4 border-primary pl-4">
              "The medical community largely anticipated that combining the abilities of doctors and A.I. would be the best of both worlds, 
              leading to more accurate diagnoses and more efficient care. That assumption might prove to be incorrect. A growing body of 
              research suggests that A.I. is outperforming doctors, even when they use it as a tool."
            </blockquote>
          </div>

          <p className="text-lg">
            The authors propose three distinct models for AI-physician collaboration:
          </p>

          <div className="grid gap-6 md:grid-cols-3 my-8">
            <div className="p-6 bg-white/80 dark:bg-gray-900/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 rounded-xl">
              <h3 className="text-xl font-semibold mb-3">Physician-First Approach</h3>
              <p>Doctors gather initial patient information through interviews and examinations, followed by AI analysis for diagnosis suggestions.</p>
            </div>
            
            <div className="p-6 bg-white/80 dark:bg-gray-900/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 rounded-xl">
              <h3 className="text-xl font-semibold mb-3">AI-First Analysis</h3>
              <p>AI systems analyze medical data and suggest diagnoses, with physicians applying clinical judgment to finalize treatment plans.</p>
            </div>
            
            <div className="p-6 bg-white/80 dark:bg-gray-900/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 rounded-xl">
              <h3 className="text-xl font-semibold mb-3">Complete Separation</h3>
              <p>AI handles routine cases independently, while doctors focus on complex disorders and rare conditions.</p>
            </div>
          </div>

          <p className="text-lg">
            The essay highlights promising evidence supporting these approaches, including a Swedish trial where AI-assisted screening led to 
            20% more breast cancer identifications while reducing radiologist workload by nearly half.
          </p>

          <div className="my-8">
            <a 
              href="https://www.nytimes.com/2025/02/02/opinion/ai-doctors-medicine.html" 
              target="_blank" 
              rel="noopener noreferrer"
              className="inline-flex items-center px-6 py-3 text-base font-medium rounded-xl shadow-lg text-white bg-primary hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-all duration-300 hover:shadow-xl"
            >
              Read the Full Essay in The New York Times
            </a>
          </div>
        </div>

        <section className="mb-12">
          <h2 className="text-3xl font-bold mb-6">Experience the Future of AI in Radiology</h2>
          <div className="bg-white/80 dark:bg-gray-900/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 rounded-xl p-6">
            <div className="flex flex-col md:flex-row gap-6">
              <div className="flex-1">
                <p className="mb-4 text-lg">
                  See how a2z is implementing these principles to enhance radiological practice.
                </p>
                <div className="bg-primary/5 dark:bg-primary/10 rounded-lg p-4">
                  <h3 className="text-lg font-semibold mb-3 text-gray-900 dark:text-gray-100">
                    Schedule a demo to:
                  </h3>
                  <div className="grid gap-3 text-gray-700 dark:text-gray-300">
                    <p className="border-b border-gray-200 dark:border-gray-700 pb-2">
                      See our AI-assisted workflow in action
                    </p>
                    <p className="border-b border-gray-200 dark:border-gray-700 pb-2">
                      Learn about our validation studies
                    </p>
                    <p className="pb-2">
                      Discuss collaboration opportunities
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center md:justify-end">
                <button 
                  onClick={openCalendly}
                  className="inline-flex items-center px-6 py-3 text-base font-medium rounded-xl shadow-lg text-white bg-primary hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-all duration-300 hover:shadow-xl"
                >
                  Schedule a Demo
                </button>
              </div>
            </div>
          </div>
        </section>
      </article>
    </ArticleLayout>
  );
};

export default NYTGuestEssay; 