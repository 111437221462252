import { FileText, Newspaper, LineChart, BarChart2, Users, AlertTriangle, BookOpen } from 'lucide-react';
import contentStore from '../content-store.json';

interface ContentMetadata {
  title: string;
  description: string;
  date: string;
  authors: string[];
  category: string;
  path: string;
}

interface ContentEntry {
  metadata: ContentMetadata;
}

interface ContentStore {
  [key: string]: ContentEntry;
}

// Icon mapping based on category and path patterns
const getIcon = (category: string, path: string) => {
  if (path.includes('research-paper')) return FileText;
  if (path.includes('validation')) return BarChart2;
  if (path.includes('analysis')) return AlertTriangle;
  if (path.includes('settings')) return Users;
  if (category.toLowerCase() === 'news') return Newspaper;
  if (category.toLowerCase() === 'company') return BookOpen;
  return LineChart; // Default for case studies
};

export interface Article {
  title: string;
  description: string;
  path: string;
  icon: any;
  category: 'Case Studies' | 'Research' | 'Analysis' | 'News' | 'Company' | 'Performance';
  authors?: string[];
  date?: string;
}

// Normalize path to always start with /
const normalizePath = (path: string) => {
  // Remove any leading slashes first
  const cleanPath = path.replace(/^\/+/, '');
  // Add a single leading slash
  return `/${cleanPath}`;
};

// Get the full path for an article
const getFullPath = (category: string, path: string): string => {
  if (category.toLowerCase() === 'performance') {
    return `performance/${path}`;
  }
  return path;
};

// Hardcoded articles for ones without markdown
const HARDCODED_ARTICLES: Article[] = [
  {
    title: "External Validation Study Released",
    description: "Our comprehensive validation study demonstrates a2z-1's performance across 21 conditions, validated on over 14,000 CT scans from three health systems.",
    path: "/updates/research-paper",
    date: "2024-12-17T09:00:00-04:00",
    icon: FileText,
    category: "Research",
    authors: ["Pranav Rajpurkar", "Samir Rajpurkar"]
  },
  {
    title: "Performance Across 21 Conditions",
    description: "Detailed analysis of a2z-1's performance metrics across all supported conditions, with insights into sensitivity and specificity.",
    path: "/updates/external-validation",
    date: "2024-10-21T10:00:00-04:00",
    icon: BarChart2,
    category: "Analysis",
    authors: ["Pranav Rajpurkar", "Samir Rajpurkar"]
  },
  {
    title: "High-Confidence AI Findings",
    description: "Analysis of cases where a2z-1 demonstrated high confidence in detecting critical findings that were initially missed.",
    path: "/updates/miss-analysis",
    date: "2024-11-04T11:00:00-04:00",
    icon: AlertTriangle,
    category: "Analysis",
    authors: ["Pranav Rajpurkar", "Samir Rajpurkar"]
  },
  {
    title: "Performance in Diverse Settings",
    description: "Comprehensive analysis showing consistent performance across different patient demographics and imaging protocols.",
    path: "/updates/subgroup-analysis",
    date: "2024-10-28T14:00:00-04:00",
    icon: Users,
    category: "Analysis",
    authors: ["Pranav Rajpurkar", "Samir Rajpurkar"]
  },
  {
    title: "Eric Topol Highlights a2z at RSNA 2024",
    description: "Dr. Eric Topol discusses a2z's approach to AI in radiology during his keynote at RSNA 2024, highlighting our focus on clinical validation and safety.",
    path: "/updates/rsna-2024",
    date: "2024-12-03T09:00:00-04:00",
    icon: Newspaper,
    category: "News"
  },
  {
    title: "a2z Featured in Financial Times",
    description: "Financial Times features a2z's mission to create a world where no disease is missed through the power of AI in radiology.",
    path: "/updates/ft-feature",
    date: "2024-11-07T08:00:00-04:00",
    icon: Newspaper,
    category: "News"
  },
  {
    title: "Launching a2z Radiology AI: Vision for a World Where no Disease is Missed",
    description: "a2z Radiology AI emerges from stealth, introducing AI-powered analytics for radiologists with a2z-1 for abdominal-pelvis CT interpretation.",
    path: "/story",
    date: "2024-10-07T13:00:00-04:00",
    icon: BookOpen,
    category: "Company",
    authors: ["Pranav Rajpurkar", "Samir Rajpurkar"]
  },
  {
    title: "The Robot Doctor Will See You Now",
    description: "Dr. Pranav Rajpurkar and Dr. Eric Topol discuss in the New York Times how AI is outperforming doctors in certain tasks and propose a new division of labor between human physicians and AI systems.",
    path: "/updates/nyt-guest-essay",
    date: "2025-02-02T09:00:00-04:00",
    icon: Newspaper,
    category: "News",
    authors: ["Pranav Rajpurkar"]
  }
];

// Map category from content store to Article category
const mapCategory = (category: string): Article['category'] => {
  const lowerCategory = category.toLowerCase();
  switch (lowerCategory) {
    case 'performance':
      return 'Case Studies';
    case 'research':
      return 'Research';
    case 'analysis':
      return 'Analysis';
    case 'news':
      return 'News';
    case 'company':
      return 'Company';
    default:
      return 'Case Studies';
  }
};

// Convert markdown content to articles
const markdownArticles: Article[] = Object.entries(contentStore as ContentStore).map(([_, data]) => {
  const category = data.metadata.category.toLowerCase();
  const fullPath = getFullPath(category, data.metadata.path);
  const normalizedPath = normalizePath(fullPath);

  return {
    title: data.metadata.title,
    description: data.metadata.description,
    path: normalizedPath,
    icon: getIcon(data.metadata.category, fullPath),
    category: mapCategory(data.metadata.category),
    authors: data.metadata.authors,
    date: data.metadata.date
  };
});

// Combine both sources, with markdown taking precedence
export const ARTICLES: Article[] = [
  ...markdownArticles,
  ...HARDCODED_ARTICLES.filter(article => 
    !markdownArticles.some(md => normalizePath(md.path) === normalizePath(article.path))
  )
];

// Helper function to get article by path
export const getArticleByPath = (path: string): Article | undefined => {
  const normalizedPath = normalizePath(path);
  return ARTICLES.find(article => normalizePath(article.path) === normalizedPath);
};

// Helper function to get articles by category
export const getArticlesByCategory = (category: Article['category']): Article[] => {
  // Special handling for Case Studies
  if (category === 'Case Studies') {
    return ARTICLES.filter(article => 
      article.category === 'Case Studies' || 
      article.path.includes('performance/')
    ).sort((a, b) => {
      if (!a.date || !b.date) return 0;
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
  }

  return ARTICLES.filter(article => article.category === category)
    .sort((a, b) => {
      if (!a.date || !b.date) return 0;
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
};

// Export dates for all articles
export const DATES: { [key: string]: string } = {
  ...Object.entries(contentStore as ContentStore).reduce((acc, [_, data]) => {
    const category = data.metadata.category.toLowerCase();
    const fullPath = getFullPath(category, data.metadata.path);
    const normalizedPath = normalizePath(fullPath);
    
    return {
      ...acc,
      [normalizedPath]: data.metadata.date
    };
  }, {}),
  ...HARDCODED_ARTICLES.reduce((acc, article) => ({
    ...acc,
    [normalizePath(article.path)]: article.date || ''
  }), {})
};

export function getArticleDate(path: string): string | undefined {
  const normalizedPath = normalizePath(path);
  return DATES[normalizedPath];
} 