import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { PageMetadata } from '../shared/PageMetadata';
import { generateMetadata } from '../../metadata/siteMetadata';
import { ARTICLES, getArticlesByCategory, Article, getArticleDate } from '../../constants/articles';
import { formatDate } from '../../utils/dateUtils';

const sortByDate = (a: Article, b: Article) => {
  const dateA = getArticleDate(a.path);
  const dateB = getArticleDate(b.path);
  
  if (!dateA && !dateB) return 0;
  if (!dateA) return 1;
  if (!dateB) return -1;
  return new Date(dateB).getTime() - new Date(dateA).getTime();
};

const Updates = () => {
  const [searchParams] = useSearchParams();
  const category = searchParams.get('category') as Article['category'] | null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const metadata = generateMetadata({
    title: category ? `${category} | a2z Radiology AI` : 'Updates | a2z Radiology AI',
    description: 'Latest research findings, performance analyses, and company updates from a2z Radiology AI.',
    type: 'website'
  });

  // Get articles based on category filter
  const articles = category ? getArticlesByCategory(category) : 
    [...ARTICLES].sort(sortByDate);

  return (
    <div className="min-h-screen bg-gradient-to-br from-white via-gray-50 to-white dark:from-gray-950 dark:via-gray-900 dark:to-gray-950">
      <PageMetadata {...metadata} />
      
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 lg:pt-40 pb-24">
        <div className="max-w-4xl mx-auto">
          <motion.h1 
            className="text-4xl sm:text-5xl font-bold mb-6 text-gray-900 dark:text-accent"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            {category || 'Updates'}
          </motion.h1>
          
          <motion.p 
            className="text-xl text-gray-700 dark:text-gray-300 mb-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            {category ? 
              `Latest ${category.toLowerCase()} from a2z Radiology AI.` :
              'Latest research findings, performance analyses, and company updates from a2z Radiology AI.'
            }
          </motion.p>

          <div className="space-y-8">
            {articles.map((post, index) => (
              <motion.div
                key={post.path}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 * (index + 1) }}
              >
                <Link 
                  to={post.path}
                  className="block bg-white/80 dark:bg-gray-900/50 backdrop-blur-sm border border-gray-200 dark:border-gray-800 rounded-xl p-6 hover:bg-gray-50 dark:hover:bg-gray-900/60 transition-all duration-300"
                >
                  <div className="flex items-start space-x-4">
                    <div className="bg-primary/10 dark:bg-primary/20 p-3 rounded-xl">
                      <post.icon className="w-6 h-6 text-primary dark:text-accent" />
                    </div>
                    <div className="flex-1">
                      <div className="flex items-center gap-3 mb-2">
                        <span className="text-sm text-primary dark:text-accent font-medium">
                          {post.category}
                        </span>
                        {getArticleDate(post.path) && (
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {formatDate(getArticleDate(post.path))}
                          </span>
                        )}
                        {post.authors && (
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            • {post.authors.join(', ')}
                          </span>
                        )}
                      </div>
                      <h2 className="text-2xl font-semibold text-gray-900 dark:text-accent mb-2">
                        {post.title}
                      </h2>
                      <p className="text-gray-700 dark:text-gray-300 mb-4">
                        {post.description}
                      </p>
                      <div className="flex items-center text-primary dark:text-accent font-semibold">
                        Read More
                        <ArrowRight className="ml-2 h-4 w-4" />
                      </div>
                    </div>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Updates; 