import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ShinyButton } from '../shared/Shared';
import { generateMetadata } from '../../metadata/siteMetadata';
import ArticleLayout from '../shared/ArticleLayout';
import { getArticleDate } from '../../constants/articles';

const RSNAMention: React.FC<{ openCalendly: () => void }> = ({ openCalendly }) => {
  const metadata = generateMetadata({
    title: 'Eric Topol at RSNA 2024',
    description: 'Dr. Eric Topol highlights a2z-1\'s performance in his keynote at RSNA 2024.',
    type: 'article',
    publishedDate: getArticleDate('/updates/rsna-2024')
  });

  return (
    <ArticleLayout metadata={metadata}>
      <article>
        <motion.h1
          className="text-4xl sm:text-5xl font-bold mb-8 text-primary dark:text-accent"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          {metadata.title}
        </motion.h1>

        <motion.div
          className="flex items-center space-x-4 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <img
            src="/assets/logos/a2z-logo-square-500px.png"
            alt="a2z Radiology AI"
            className="w-10 h-10 rounded-full object-cover"
          />
          <div>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              December 3, 2024
            </p>
          </div>
        </motion.div>

        <motion.div
          className="prose dark:prose-invert max-w-none"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <p className="text-lg">
            At RSNA 2024's plenary session "AI's Transformation of Medicine" (December 2), renowned medical AI expert Dr. Eric Topol highlighted a2z Radiology AI's advanced capabilities in multimodal AI, positioning us at the forefront of AI innovation in medical imaging.
          </p>

          <div className="my-8 aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-lg">
            <video
              controls
              className="w-full"
              playsInline
            >
              <source src="/figs/news/rsna/topol-presentation.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="my-8 p-8 bg-gradient-to-br from-blue-50 to-gray-100 dark:from-gray-800 dark:to-gray-900 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Key Highlights from Dr. Topol's Presentation</h2>
            
            <div className="space-y-6">
              <div className="ml-4 border-l-4 border-primary dark:border-accent pl-4">
                <p className="text-lg italic">
                  "And then following that, [they] went on to do a2z Radiology AI [...] the first grounded reports."
                </p>
              </div>

              <p className="leading-relaxed">
                Dr. Topol emphasized the evolution of AI in radiology, from narrow single-task solutions to today's advanced multimodal systems. He highlighted four key levels of advancement:
              </p>

              <ol className="list-decimal pl-6 space-y-4">
                <li>
                  <strong>Level 1:</strong> Narrow tasks like lung nodule detection
                </li>
                <li>
                  <strong>Level 2:</strong> Diagnostic tools linked to care pathways
                </li>
                <li>
                  <strong>Level 3:</strong> Foundation models for report generation
                </li>
                <li>
                  <strong>Level 4:</strong> Advanced multimodal AI - where a2z Radiology AI is leading the way
                </li>
              </ol>

              <p className="leading-relaxed">
                Dr. Topol specifically showcased our system's multi-disease detection capabilities in abdominal CT scans, highlighting our comprehensive approach to analyzing conditions including pancreatitis, fatty liver, and aortic involvement.
              </p>
            </div>
          </div>

          <p>
            This recognition from Dr. Topol, a leading voice in medical AI, validates our approach to building comprehensive, multimodal AI solutions that can handle the complexity of real-world radiology practice. Our ability to analyze multiple conditions simultaneously demonstrates our commitment to creating AI that truly supports radiologists in their daily work.
          </p>

          <div className="bg-blue-100 dark:bg-blue-900 p-6 rounded-lg mt-12">
            <h2 className="text-2xl font-bold mb-4">Experience a2z's Advanced Capabilities</h2>
            <p className="mb-6">
              Want to see how our multimodal AI technology can enhance your radiology practice? Schedule a demo to experience our capabilities firsthand.
            </p>
            <ShinyButton onClick={openCalendly}>
              Schedule a Demo
            </ShinyButton>
          </div>

          <div className="mt-12">
            <Link to="/" className="text-primary dark:text-accent hover:underline block">
              &larr; Back to Home
            </Link>
          </div>
        </motion.div>
      </article>
    </ArticleLayout>
  );
};

export default RSNAMention; 