import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';

interface GetInvolvedProps {
  openCalendly: () => void;
}

const GetInvolved: React.FC<GetInvolvedProps> = ({ openCalendly }) => {
  return (
    <section className="mt-32 mb-20">
      <motion.div 
        className="bg-gradient-to-br from-white/80 via-white/70 to-white/80 dark:from-gray-900/80 dark:via-gray-900/70 dark:to-gray-900/80 backdrop-blur-sm border border-accent/10 rounded-2xl overflow-hidden"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        <div className="relative p-8 md:p-12">
          {/* Background accent elements */}
          <div className="absolute top-0 right-0 w-64 h-64 bg-accent/5 dark:bg-accent/10 rounded-full blur-3xl -translate-y-1/2 translate-x-1/2" />
          <div className="absolute bottom-0 left-0 w-64 h-64 bg-primary/5 dark:bg-primary/10 rounded-full blur-3xl translate-y-1/2 -translate-x-1/2" />
          
          {/* Content */}
          <div className="relative">
            <motion.h2 
              className="text-4xl sm:text-5xl font-bold text-accent mb-6"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              Get Involved
            </motion.h2>
            
            <div className="flex flex-col lg:flex-row gap-12">
              <motion.div 
                className="flex-1"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.3 }}
              >
                <p className="text-xl text-gray-700 dark:text-gray-300 mb-8">
                  Join our network of radiologists shaping the future of radiology AI.
                </p>
                <div className="bg-white/50 dark:bg-gray-800/50 rounded-xl p-8">
                  <h3 className="text-xl font-medium mb-6 text-gray-900 dark:text-gray-100">
                    Schedule a demo to:
                  </h3>
                  <div className="grid gap-6 text-lg text-gray-700 dark:text-gray-300">
                    <div className="flex items-center space-x-4 border-b border-accent/10 pb-6">
                      <div className="w-2 h-2 rounded-full bg-accent/70" />
                      <p>Preview our enhanced technology in action</p>
                    </div>
                    <div className="flex items-center space-x-4 border-b border-accent/10 pb-6">
                      <div className="w-2 h-2 rounded-full bg-accent/70" />
                      <p>Discuss research collaboration opportunities</p>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div className="w-2 h-2 rounded-full bg-accent/70" />
                      <p>Learn about our validation studies</p>
                    </div>
                  </div>
                </div>
              </motion.div>
              
              <motion.div 
                className="flex items-center justify-center lg:justify-end"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.4 }}
              >
                <button
                  onClick={openCalendly}
                  className="group inline-flex items-center px-8 py-4 text-lg font-medium rounded-xl text-white bg-accent hover:bg-accent/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent transition-all duration-300"
                >
                  Schedule a Demo
                  <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
                </button>
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default GetInvolved; 