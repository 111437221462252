import React from 'react';
import { ContentData } from '../../utils/contentLoader';
import { formatDate } from '../../utils/dateUtils';

interface MarkdownContentProps {
  contentData: ContentData;
}

const MarkdownContent: React.FC<MarkdownContentProps> = ({ contentData }) => {
  const { metadata, content } = contentData;
  const formattedDate = formatDate(metadata.date);

  return (
    <>
      <header className="mb-16 max-w-[700px] mx-auto">
        <h1 className="text-4xl sm:text-[3.5rem] font-normal text-accent leading-[1.1] mb-8">
          {metadata.title}
        </h1>
        <div className="flex items-center space-x-4">
          <img 
            src="/assets/logos/a2z-logo-square-500px.png" 
            alt="a2z Radiology AI" 
            className="w-10 h-10 rounded-full object-cover"
          />
          <div className="flex items-center space-x-4">
            {metadata.authors.map((author) => (
              <img
                key={author}
                src={`/team/${author.split(' ').join('')}.jpg`}
                alt={author}
                className="w-12 h-12 rounded-full object-cover border-2 border-accent/20"
              />
            ))}
          </div>
          <div>
              <p className="text-base text-gray-700 dark:text-gray-300">
                By{' '}
                {metadata.authors.map((author, index) => {
                  const linkedinUsername = author === 'Pranav Rajpurkar' 
                    ? 'pranavrajpurkar'
                    : 'siddhant-dogra-1765abb1';
                  return (
                    <React.Fragment key={author}>
                      <a
                        href={`https://www.linkedin.com/in/${linkedinUsername}/`}
                        className="text-primary dark:text-accent hover:underline font-medium"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {author}
                      </a>
                      {index < metadata.authors.length - 1 ? ' and ' : ''}
                    </React.Fragment>
                  );
                })}
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-400 mt-1 italic">
              {formattedDate}
            </p>
          </div>
        </div>
      </header>
      <div 
        className="prose dark:prose-invert max-w-[700px] mx-auto
          prose-p:text-lg prose-p:text-gray-700 dark:prose-p:text-gray-300
          prose-h2:text-3xl prose-h2:font-bold prose-h2:mb-6 prose-h2:text-gray-900 dark:prose-h2:text-gray-100
          prose-a:text-primary dark:prose-a:text-accent prose-a:no-underline hover:prose-a:underline
          prose-strong:font-semibold prose-strong:text-inherit
          prose-video:rounded-lg prose-video:my-8
          prose-img:rounded-lg
          [&>*+*]:mt-6"
        dangerouslySetInnerHTML={{ __html: content }} 
      />
    </>
  );
};

export default MarkdownContent; 