import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Sun, Moon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { TwitterIcon } from './Icons';

// Navbar Component
interface NavbarProps {
  openCalendly: () => void;
}

export const Navbar: React.FC<NavbarProps> = ({ openCalendly }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="fixed top-0 left-0 right-0 z-50">
      <div className="backdrop-blur-md bg-white/80 dark:bg-gray-900/80 border-b border-gray-200 dark:border-gray-800/40">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            {/* Logo and Navigation */}
            <div className="flex items-center space-x-8">
              <Link to="/" className="flex items-center">
                <img
                  src="/assets/logos/a2z-logo-square-500px.png"
                  alt="a2z Radiology AI"
                  className="h-8 w-8 mr-2"
                />
                <span className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                  a2z Radiology AI
                </span>
              </Link>
              
              {/* Desktop Navigation */}
              <div className="hidden md:flex items-center space-x-8">
                <Link to="/story" className="text-sm font-medium hover:text-primary dark:hover:text-accent transition-colors">
                  About Us
                </Link>
                <Link to="/updates?category=Research" className="text-sm font-medium hover:text-primary dark:hover:text-accent transition-colors">
                  Research
                </Link>
                <Link to="/updates?category=Case+Studies" className="text-sm font-medium hover:text-primary dark:hover:text-accent transition-colors">
                  Case Studies
                </Link>
                <Link to="/updates" className="text-sm font-medium hover:text-primary dark:hover:text-accent transition-colors">
                  News & Updates
                </Link>
              </div>
            </div>

            {/* Mobile Menu Button */}
            <div className="md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
              >
                <span className="sr-only">Open menu</span>
                {isOpen ? (
                  <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                )}
              </button>
            </div>

            {/* Desktop CTA and Social Links */}
            <div className="hidden md:flex items-center space-x-6">
              <div className="flex items-center space-x-4 border-r border-gray-200 dark:border-gray-700 pr-6">
                <a 
                  href="http://linkedin.com/company/a2z-radiology-ai" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-gray-500 hover:text-primary dark:text-gray-400 dark:hover:text-accent transition-colors"
                  aria-label="Follow us on LinkedIn"
                >
                  <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                  </svg>
                </a>
                <a 
                  href="https://x.com/a2zradiologyai" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-gray-500 hover:text-primary dark:text-gray-400 dark:hover:text-accent transition-colors"
                  aria-label="Follow us on X (formerly Twitter)"
                >
                  <TwitterIcon className="h-4 w-4" />
                </a>
              </div>
              <ShinyButton onClick={openCalendly} className="!py-1.5 !text-sm">
                Schedule a Demo
              </ShinyButton>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="md:hidden border-t border-gray-200 dark:border-gray-800">
            <div className="px-4 py-2 space-y-2">
              <Link 
                to="/story" 
                className="block text-sm font-medium hover:text-primary dark:hover:text-accent transition-colors py-1"
                onClick={() => setIsOpen(false)}
              >
                About Us
              </Link>
              <Link 
                to="/updates?category=Research" 
                className="block text-sm font-medium hover:text-primary dark:hover:text-accent transition-colors py-1"
                onClick={() => setIsOpen(false)}
              >
                Research
              </Link>
              <Link 
                to="/updates?category=Case+Studies" 
                className="block text-sm font-medium hover:text-primary dark:hover:text-accent transition-colors py-1"
                onClick={() => setIsOpen(false)}
              >
                Case Studies
              </Link>
              <Link 
                to="/updates" 
                className="block text-sm font-medium hover:text-primary dark:hover:text-accent transition-colors py-1"
                onClick={() => setIsOpen(false)}
              >
                News & Updates
              </Link>
              <div className="flex items-center space-x-4 py-2 border-t border-gray-200 dark:border-gray-800 mt-2">
                <a 
                  href="http://linkedin.com/company/a2z-radiology-ai" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-gray-500 hover:text-primary dark:text-gray-400 dark:hover:text-accent transition-colors"
                  aria-label="Follow us on LinkedIn"
                >
                  <span className="flex items-center">
                    <svg className="h-4 w-4 mr-2" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                    </svg>
                    LinkedIn
                  </span>
                </a>
                <a 
                  href="https://x.com/a2zradiologyai" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-gray-500 hover:text-primary dark:text-gray-400 dark:hover:text-accent transition-colors"
                  aria-label="Follow us on X (formerly Twitter)"
                >
                  <span className="flex items-center">
                    <TwitterIcon className="h-4 w-4 mr-2" />
                    Twitter
                  </span>
                </a>
              </div>
              <ShinyButton onClick={() => { openCalendly(); setIsOpen(false); }} className="w-full !py-1 !text-sm">
                Schedule a Demo
              </ShinyButton>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

// Footer Component
interface FooterProps {
  isDarkMode: boolean;
  toggleTheme: () => void;
  openCalendly: () => void;
}

export const Footer: React.FC<FooterProps> = ({ isDarkMode, toggleTheme, openCalendly }) => {
  return (
    <footer className="bg-white dark:bg-dark border-t border-primary/10 dark:border-accent/10 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="col-span-1">
            <img src="/assets/logos/a2z-logo-linear.png" alt="a2z Logo" className="h-6 w-auto mb-3 opacity-75" />
            <p className="text-sm text-primary/60 dark:text-accent/60">
              Building AI that helps radiologists catch critical findings.
            </p>
          </div>

          {/* Navigation */}
          <div className="col-span-1">
            <h3 className="text-primary dark:text-accent text-sm font-medium mb-3">Navigation</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-sm text-primary/60 dark:text-accent/60 hover:text-primary dark:hover:text-accent transition-colors">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/story" className="text-sm text-primary/60 dark:text-accent/60 hover:text-primary dark:hover:text-accent transition-colors">
                  The a2z Story
                </Link>
              </li>
              <li>
                <Link to="/updates" className="text-sm text-primary/60 dark:text-accent/60 hover:text-primary dark:hover:text-accent transition-colors">
                  Updates
                </Link>
              </li>
            </ul>
          </div>

          {/* Content */}
          <div className="col-span-1">
            <h3 className="text-primary dark:text-accent text-sm font-medium mb-3">Content</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/updates?category=Company" className="text-sm text-primary/60 dark:text-accent/60 hover:text-primary dark:hover:text-accent transition-colors">
                  Company
                </Link>
              </li>
              <li>
                <Link to="/updates?category=Case+Studies" className="text-sm text-primary/60 dark:text-accent/60 hover:text-primary dark:hover:text-accent transition-colors">
                  Case Studies
                </Link>
              </li>
              <li>
                <Link to="/updates?category=Research" className="text-sm text-primary/60 dark:text-accent/60 hover:text-primary dark:hover:text-accent transition-colors">
                  Research
                </Link>
              </li>
              <li>
                <Link to="/updates?category=Analysis" className="text-sm text-primary/60 dark:text-accent/60 hover:text-primary dark:hover:text-accent transition-colors">
                  Analysis
                </Link>
              </li>
              <li>
                <Link to="/updates?category=News" className="text-sm text-primary/60 dark:text-accent/60 hover:text-primary dark:hover:text-accent transition-colors">
                  News
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div className="col-span-1">
            <h3 className="text-primary dark:text-accent text-sm font-medium mb-3">Contact</h3>
            <ShinyButton onClick={openCalendly} className="mb-3 !text-sm">
              Schedule a Demo
            </ShinyButton>
            <p className="text-sm text-primary/60 dark:text-accent/60">
              For partnership inquiries and more information.
            </p>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-primary/10 dark:border-accent/10 mt-8 pt-6 flex flex-col md:flex-row justify-between items-center">
          <p className="text-sm text-primary/60 dark:text-accent/60 mb-4 md:mb-0">
            © {new Date().getFullYear()} a2z Radiology AI. All rights reserved.
          </p>
          <div className="flex items-center space-x-6">
            <Link to="/privacy-policy" className="text-sm text-primary/60 dark:text-accent/60 hover:text-primary dark:hover:text-accent transition-colors">
              Privacy Policy
            </Link>
            <div className="flex items-center space-x-4 border-l border-primary/10 dark:border-accent/10 pl-6">
              <a 
                href="http://linkedin.com/company/a2z-radiology-ai" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-primary/60 dark:text-accent/60 hover:text-primary dark:hover:text-accent transition-colors"
                aria-label="Follow us on LinkedIn"
              >
                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                </svg>
              </a>
              <a 
                href="https://x.com/a2zradiologyai" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-primary/60 dark:text-accent/60 hover:text-primary dark:hover:text-accent transition-colors"
                aria-label="Follow us on X (formerly Twitter)"
              >
                <TwitterIcon className="h-5 w-5" />
              </a>
            </div>
            <button
              onClick={toggleTheme}
              className="p-2 hover:bg-primary/10 dark:hover:bg-accent/10 rounded-lg transition-colors"
              aria-label="Toggle theme"
            >
              {isDarkMode ? (
                <Sun className="h-5 w-5 text-primary/60 dark:text-accent/60 hover:text-primary dark:hover:text-accent" />
              ) : (
                <Moon className="h-5 w-5 text-primary/60 dark:text-accent/60 hover:text-primary dark:hover:text-accent" />
              )}
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

// SidebarNav Component
interface SidebarNavProps {
  sections: { name: string; to: string }[];
}

export const SidebarNav: React.FC<SidebarNavProps> = ({ sections }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  React.useEffect(() => {
    const handleScroll = () => {
      const homeSection = document.getElementById('home');
      if (homeSection) {
        const homeSectionBottom = homeSection.offsetTop + homeSection.offsetHeight;
        setIsVisible(window.scrollY > homeSectionBottom - 100);
      } else {
        setIsVisible(false);
      }

      // Update active section based on scroll position
      sections.forEach(({ to }) => {
        const element = document.getElementById(to);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 150 && rect.bottom >= 150) {
            setActiveSection(to);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, [sections]);

  if (!isVisible) return null;

  return (
    <nav className="hidden lg:block fixed left-0 top-1/2 transform -translate-y-1/2 z-50 ml-2">
      <div className="relative">
        <div className="bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm rounded-lg shadow-lg py-2 px-1">
          <ul className="space-y-1">
            {sections.map(({ name, to }) => (
              <li key={to}>
                <ScrollLink
                  to={to}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={100}
                  isDynamic={true}
                  className={`text-sm transition-all block py-1.5 px-2 rounded-md cursor-pointer whitespace-nowrap
                    ${activeSection === to 
                      ? 'bg-primary/10 dark:bg-primary/20 text-primary dark:text-accent font-semibold'
                      : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700/50'
                    }`}
                  onSetActive={() => setActiveSection(to)}
                >
                  {name}
                </ScrollLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

// ScrollProgressBar Component
export const ScrollProgressBar: React.FC = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const totalScroll =
        document.documentElement.scrollHeight - window.innerHeight;
      const currentScroll = window.scrollY;
      const scrollPercentage = (currentScroll / totalScroll) * 100;
      setScrollProgress(scrollPercentage);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <motion.div
      className="fixed top-0 left-0 right-0 h-1 bg-primary dark:bg-secondary z-50"
      style={{ scaleX: scrollProgress / 100 }}
    />
  );
};

// ShinyButton Component
interface ShinyButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}

export const ShinyButton: React.FC<ShinyButtonProps> = ({ children, className = '', ...props }) => {
  return (
    <button
      className={`
        relative group overflow-hidden rounded-xl
        bg-primary/10 dark:bg-accent/10 hover:bg-primary/20 dark:hover:bg-accent/20
        text-primary dark:text-accent font-medium
        px-4 py-2 text-sm
        transition-all duration-300
        ${className}
      `}
      {...props}
    >
      <span className="relative z-10 flex items-center gap-2">
        {children}
        <ArrowRight className="w-4 h-4" />
      </span>
      <div
        className="absolute inset-0 -z-10 bg-gradient-to-r from-primary/20 to-accent/20 dark:from-accent/20 dark:to-primary/20
                   translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"
      />
    </button>
  );
};

export { default as AuthorSection } from './AuthorSection';
