import React from 'react';

interface AuthorSectionProps {
  date: string;
  updatedDate?: string;
  showCompanyLogo?: boolean;
  authors: Array<{
    name: string;
    title: string;
    image: string;
    linkedin: string;
  }>;
  companyLogo?: string;
  companyName?: string;
  companyUrl?: string;
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { 
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const AuthorSection: React.FC<AuthorSectionProps> = ({
  date,
  updatedDate,
  showCompanyLogo = true,
  authors,
  companyLogo,
  companyName,
  companyUrl
}) => {
  const formattedDate = formatDate(date);
  const formattedUpdatedDate = updatedDate ? formatDate(updatedDate) : null;

  return (
    <div className="flex items-center space-x-4 mb-8">
      {/* Company Logo */}
      {showCompanyLogo && companyLogo && (
        <img
          src={companyLogo}
          alt={companyName}
          className="w-10 h-10 rounded-full object-cover"
        />
      )}

      {/* Author Name, Date, and Image */}
      <div className="flex items-center space-x-4">
        {authors.map((author, i) => (
          <img
            key={i}
            src={author.image}
            alt={author.name}
            className="w-12 h-12 rounded-full object-cover"
          />
        ))}
        <div>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            By{' '}
            {authors.map((author, i) => (
              <React.Fragment key={i}>
                <a
                  href={author.linkedin}
                  className="text-primary dark:text-accent hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {author.name}
                </a>
                {i < authors.length - 2 && ', '}
                {i === authors.length - 2 && ' and '}
              </React.Fragment>
            ))}
            {' '}on {formattedDate}
          </p>
          {formattedUpdatedDate && (
            <span className="block mt-1 text-sm text-gray-500 dark:text-gray-400">
              Updated on {formattedUpdatedDate}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthorSection; 