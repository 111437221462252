import { remark } from 'remark';
import remarkGfm from 'remark-gfm';
import remarkRehype from 'remark-rehype';
import rehypeRaw from 'rehype-raw';
import rehypeStringify from 'rehype-stringify';
import contentStore from '../content-store.json';

export interface ContentMetadata {
  title: string;
  description: string;
  date: string;
  authors: string[];
  category: string;
  path: string;
  [key: string]: any;
}

export interface ContentData {
  metadata: ContentMetadata;
  content: string;
}

interface RawContentStore {
  [key: string]: {
    metadata: ContentMetadata;
    content: string;
  };
}

const typedContentStore = contentStore as RawContentStore;
const isDevelopment = process.env.NODE_ENV === 'development';

async function fetchMarkdownContent(contentPath: string): Promise<string | null> {
  try {
    const response = await fetch(`/content/${contentPath}`);
    if (!response.ok) {
      throw new Error(`Failed to load markdown file: ${contentPath}`);
    }
    return await response.text();
  } catch (error) {
    console.warn(`Failed to fetch markdown file directly, falling back to content store: ${error}`);
    return null;
  }
}

export async function getContentData(contentPath: string): Promise<ContentData> {
  // Remove .md extension if present and get the slug
  const slug = contentPath.split('/').pop()?.replace(/\.md$/, '') || '';
  const storeEntry = typedContentStore[slug];
  
  if (!storeEntry) {
    throw new Error(`Content not found for slug: ${slug}`);
  }

  // In development, try to fetch the markdown file directly first
  let rawContent = storeEntry.content;
  if (isDevelopment) {
    const markdownContent = await fetchMarkdownContent(contentPath);
    if (markdownContent) {
      // Extract content after the frontmatter (everything after the second ---)
      const parts = markdownContent.split('---');
      if (parts.length >= 3) {
        rawContent = parts.slice(2).join('---').trim();
      }
    }
  }

  // Use remark to convert markdown into HTML while preserving raw HTML
  const processedContent = await remark()
    .use(remarkGfm)
    .use(remarkRehype, { allowDangerousHtml: true })
    .use(rehypeRaw)
    .use(rehypeStringify)
    .process(rawContent);
  
  const htmlContent = processedContent.toString();

  return {
    metadata: storeEntry.metadata,
    content: htmlContent,
  };
}

export async function getAllContent(category: string): Promise<ContentData[]> {
  // Filter content by category
  const categoryContent = Object.entries(typedContentStore)
    .filter(([_, entry]) => entry.metadata.category === category)
    .map(async ([slug, entry]) => {
      let rawContent = entry.content;
      
      // In development, try to fetch the markdown file directly
      if (isDevelopment) {
        const contentPath = `${entry.metadata.category}/${slug}.md`;
        const markdownContent = await fetchMarkdownContent(contentPath);
        if (markdownContent) {
          const parts = markdownContent.split('---');
          if (parts.length >= 3) {
            rawContent = parts.slice(2).join('---').trim();
          }
        }
      }

      const processedContent = await remark()
        .use(remarkGfm)
        .use(remarkRehype, { allowDangerousHtml: true })
        .use(rehypeRaw)
        .use(rehypeStringify)
        .process(rawContent);

      return {
        metadata: entry.metadata,
        content: processedContent.toString()
      };
    });

  // Wait for all content to be processed
  const allContentData = await Promise.all(categoryContent);

  // Sort by date
  return allContentData.sort((a, b) => {
    if (a.metadata.date < b.metadata.date) {
      return 1;
    } else {
      return -1;
    }
  });
}

export function getMarkdownDate(contentPath: string): string | undefined {
  // Remove .md extension if present and get the slug
  const slug = contentPath.split('/').pop()?.replace(/\.md$/, '') || '';
  const content = typedContentStore[slug];
  
  if (!content || !content.metadata.date) {
    return undefined;
  }

  return content.metadata.date;
} 