export function formatDate(dateString: string | undefined): string {
  if (!dateString) return '';
  
  // Parse the date string into parts
  const [year, month, day] = dateString.split('T')[0].split('-').map(Number);
  
  // Create a date object in Eastern Time
  // Note: month is 0-based in JavaScript Date
  const date = new Date(year, month - 1, day, 12, 0, 0);
  
  // Format the date
  return new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'America/New_York'
  }).format(date);
} 