export const siteMetadata = {
  title: 'a2z Radiology AI',
  description: 'AI-powered analytics for radiologists with a2z-1 for abdominal-pelvis CT interpretation.',
  siteUrl: 'https://a2zradiology.ai',
  companyInfo: {
    name: 'a2z Radiology AI',
    url: 'https://www.a2zradiology.ai',
    logo: {
      square: '/assets/logos/a2z-logo-square-500px.png',
      linear: '/assets/logos/a2z-logo-linear.png',
      product: '/assets/logos/a2z-1-logo.png'
    }
  },
  authors: [
    {
      name: 'Pranav Rajpurkar',
      title: 'Co-founder',
      image: '/team/PranavRajpurkar.jpg',
      linkedin: 'https://www.linkedin.com/in/pranavrajpurkar/'
    },
    {
      name: 'Samir Rajpurkar',
      title: 'Co-founder',
      image: '/team/SamirRajpurkar.jpg',
      linkedin: 'https://www.linkedin.com/in/samir-rajpurkar-79291396/'
    },
    {
      name: 'Siddhant Dogra',
      title: 'Chief Resident at NYU',
      image: '/team/SiddhantDogra.jpg',
      linkedin: 'https://www.linkedin.com/in/siddhant-dogra-1765abb1/'
    }
  ],
  socialPreviewImage: 'https://a2zradiology.ai/assets/social/site-preview.png',
  socialMedia: {
    twitter: {
      card: 'summary_large_image'
    }
  }
};

export const generateMetadata = (pageMetadata: {
  title: string;
  description: string;
  image?: string;
  type?: string;
  publishedDate?: string;
  modifiedDate?: string;
  authors?: Array<{
    name: string;
    url: string;
  }>;
}) => {
  const { title, description, type = 'website', publishedDate, modifiedDate, authors } = pageMetadata;
  
  return {
    title,
    description,
    openGraph: {
      title,
      description,
      url: siteMetadata.siteUrl,
      type,
      image: siteMetadata.socialPreviewImage,
      ...(publishedDate && { publishedTime: publishedDate }),
      ...(modifiedDate && { modifiedTime: modifiedDate })
    },
    twitter: {
      card: siteMetadata.socialMedia.twitter.card,
      title,
      description,
      image: siteMetadata.socialPreviewImage
    },
    jsonLd: {
      '@context': 'https://schema.org',
      '@type': type === 'article' ? 'Article' : 'WebPage',
      headline: title,
      description,
      image: [siteMetadata.socialPreviewImage],
      ...(publishedDate && { datePublished: publishedDate }),
      ...(modifiedDate && { dateModified: modifiedDate }),
      ...(authors && {
        author: authors.map(author => ({
          '@type': 'Person',
          ...author
        }))
      }),
      publisher: {
        '@type': 'Organization',
        name: siteMetadata.companyInfo.name,
        logo: {
          '@type': 'ImageObject',
          url: siteMetadata.companyInfo.logo.square
        }
      }
    }
  };
}; 