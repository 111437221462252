import React, { useEffect, useState } from 'react';
import { getContentData, ContentData } from '../../utils/contentLoader';
import MarkdownContent from '../shared/MarkdownContent';
import ArticleLayout from '../shared/ArticleLayout';
import GetInvolved from '../shared/GetInvolved';
import { generateMetadata } from '../../metadata/siteMetadata';

interface Props {
  openCalendly?: () => void;
}

const InflammatoryIntelligence: React.FC<Props> = ({ openCalendly }) => {
  const [contentData, setContentData] = useState<ContentData | null>(null);

  useEffect(() => {
    const loadContent = async () => {
      const data = await getContentData('performance/inflammatory-intelligence.md');
      setContentData(data);
    };
    loadContent();
  }, []);

  if (!contentData) {
    return <div>Loading...</div>;
  }

  const metadata = generateMetadata({
    title: contentData.metadata.title,
    description: contentData.metadata.description,
    type: 'article',
    publishedDate: contentData.metadata.date
  });

  return (
    <ArticleLayout metadata={metadata}>
      <article>
        <MarkdownContent contentData={contentData} />
        {openCalendly && <GetInvolved openCalendly={openCalendly} />}
      </article>
    </ArticleLayout>
  );
};

export default InflammatoryIntelligence; 