import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowRight, FileText } from 'lucide-react';
import { PageMetadata } from '../shared/PageMetadata';
import { generateMetadata } from '../../metadata/siteMetadata';

const researchArticles = [
  {
    title: "External Validation Study Released",
    description: "Our comprehensive validation study demonstrates a2z-1's performance across 21 conditions, validated on over 14,000 CT scans from three health systems.",
    path: "/research/paper",
    date: "2024-12-15",
    icon: FileText
  }
];

const Research = () => {
  const metadata = generateMetadata({
    title: 'Research | a2z Radiology AI',
    description: 'Read our comprehensive validation study demonstrating a2z-1\'s performance across 21 conditions.',
    type: 'website'
  });

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-950 via-gray-900 to-gray-950">
      <PageMetadata {...metadata} />
      
      <main className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <motion.h1 
            className="text-4xl sm:text-5xl font-bold mb-6 text-accent"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Research
          </motion.h1>
          
          <motion.p 
            className="text-xl text-gray-300 mb-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Read our comprehensive validation study demonstrating a2z-1's performance across diverse clinical settings.
          </motion.p>

          <div className="space-y-8">
            {researchArticles.map((article, index) => (
              <motion.div
                key={article.path}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 * (index + 1) }}
              >
                <Link 
                  to={article.path}
                  className="block bg-gray-900/50 backdrop-blur-sm border border-gray-800 rounded-xl p-6 hover:bg-gray-900/60 transition-all duration-300"
                >
                  <div className="flex items-start space-x-4">
                    <div className="bg-primary/10 dark:bg-primary/20 p-3 rounded-xl">
                      <article.icon className="w-6 h-6 text-accent" />
                    </div>
                    <div>
                      <h2 className="text-2xl font-semibold text-accent mb-2">
                        {article.title}
                      </h2>
                      <p className="text-gray-300 mb-4">
                        {article.description}
                      </p>
                      <div className="flex items-center text-accent font-semibold">
                        Read Paper
                        <ArrowRight className="ml-2 h-4 w-4" />
                      </div>
                    </div>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Research; 